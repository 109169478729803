import { TConvertType } from '../../types';
import convertAppsList from './apps-list';
import convertCategoriesData from './categories-data';
import convertCategoryProductForm from './category-product-form';
import convertCategorySettings from './category-settings';
import convertCoeffs from './coeffs';
import convertCompanyData from './company';
import convertConfigurationDetailData from './configuration-detail';
import convertConfigurationForm from './configuration-form';
import convertConfigurationHistory from './configuration-history';
import convertConfigurationPriceData from './configuration-price';
import convertConfigurations from './configurations';
import convertFeedbackAdmin from './feedback-admin';
import convertNews from './news';
import convertNewsAdmin from './news-admin';
import convertNotifications from './notifications';
import convertPricesMonitoring from './prices-monitoring';
import convertProductAssociations from './product-associations';
import convertProductsData from './products';
import convertProductsArticlesPim from './products-articles-pim';
import convertProjects from './projects';
import convertRsUploadData from './rs-upload';
import convertStorages from './storages';
import convertUserRoles from './user-roles';
import convertUserSettings from './user-settings';
import convertUsersData from './users';
import convertUsersDifferentLoginData from './users-different-login';
import convertUsersRsData from './users-rs';

const convertData = (requestType: TConvertType, data: any) => {
    switch (requestType) {
        case 'apps-list':
            return convertAppsList(data);
        case 'users':
            return convertUsersData(data);
        case 'auth-different-users':
            return convertUsersDifferentLoginData(data);
        case 'users-rs':
            return convertUsersRsData(data);
        case 'categories-data':
            return convertCategoriesData(data);
        case 'category-settings':
            return convertCategorySettings(data);
        case 'category-product-form':
            return convertCategoryProductForm(data);
        case 'product-associations':
            return convertProductAssociations(data);
        case 'products':
            return convertProductsData(data);
        case 'products-articles-pim':
            return convertProductsArticlesPim(data);
        case 'configurations':
            return convertConfigurations(data);
        case 'configuration-detail':
            return convertConfigurationDetailData(data);
        case 'configuration-price':
            return convertConfigurationPriceData(data);
        case 'configurations-history':
            return convertConfigurationHistory(data);
        case 'rs-upload':
            return convertRsUploadData(data);
        case 'configuration-form':
            return convertConfigurationForm(data);
        case 'coeffs':
            return convertCoeffs(data);
        case 'projects':
            return convertProjects(data);
        case 'user-settings':
            return convertUserSettings(data);
        case 'user-roles':
            return convertUserRoles(data);
        case 'storages':
            return convertStorages(data);
        case 'prices-monitoring':
            return convertPricesMonitoring(data);
        case 'news':
            return convertNews(data);
        case 'news-admin':
            return convertNewsAdmin(data);
        case 'notifications':
            return convertNotifications(data);
        case 'company':
            return convertCompanyData(data);
        case 'feedback-admin':
            return convertFeedbackAdmin(data);
        default:
            return data;
    }
};

export default convertData;
