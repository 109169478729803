import { IDataProduct, IDataProductsColumn, IDataProductsResponse, IProduct, IProductsColumn, IProductsResponse } from '../../types';

const columnsNames: { [name: string]: string } = {
    pricelist_id: 'articul',
    price: 'priceRs',
    configurator_price: 'priceCustom',
    use_configurator_price: 'priceCustomUsed',
};

const convertColumn = ({ name, display_name, sort_index_in_list_tmc, ...rest }: IDataProductsColumn) =>
    ({
        name: columnsNames[name] ?? name,
        title: display_name,
        className: name.replace(/__|_/g, '-'),
        sortIndex: sort_index_in_list_tmc,
        ...rest,
    } as IProductsColumn);

const convertProduct = ({
    id,
    name,
    naming,
    pricelist_id,
    additional_items,
    price,
    configurator_price,
    use_configurator_price,
    is_virtual,
    is_active,
    ...rest
}: IDataProduct) =>
    ({
        id,
        name,
        naming,
        isVirtual: is_virtual ?? false,
        isActive: is_active ?? true,
        articul: pricelist_id,
        priceRs: price,
        priceCustom: configurator_price,
        priceCustomUsed: use_configurator_price,
        additionalItems: [...(additional_items || [])],
        attributes: { ...rest },
    } as IProduct);

const convertProductsData = ({ items, columns: columnsData, count, page, prev, next, pages_total }: IDataProductsResponse) =>
    ({
        products: items.map(product => convertProduct(product)),
        columns: columnsData.map(column => convertColumn(column)),
        page,
        pagePrev: prev,
        pageNext: next,
        pagesTotal: pages_total,
        itemsTotal: count,
    } as IProductsResponse);

export default convertProductsData;
export { columnsNames, convertColumn, convertProduct };
